<template>
  <div>
    <b-button size="sm" style="margin: 0 5px" :variant="selected === 'deposits' ? 'success' : 'outline-success'" @click="setSelected('deposits')">Deposits</b-button>
    <b-button size="sm" style="margin: 0 5px" :variant="selected === 'withdrawals' ? 'danger' : 'outline-danger'" @click="setSelected('withdrawals')">Withdrawals</b-button>
    <b-button size="sm" style="margin: 0 5px" :variant="selected === 'all' ? 'primary' : 'outline-primary'" @click="setSelected('all')">All</b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: 'deposits',  // default selected value
      options: [
        { text: 'Deposits', value: 'deposits' },
        { text: 'Withdrawals', value: 'withdrawals' }
      ]
    };
  },
  methods: {
    setSelected(value) {
      this.selected = value;
      this.$emit('input', this.selected);
    }
  }
};
</script>

<style scoped>
/* Green for Deposits */
.deposit-button input[type="radio"]:checked + .custom-control-label::before {
  background-color: #4CAF50;
}

.withdraw-button input[type="radio"]:checked + .custom-control-label::before {
  background-color: #F44336;
}

/* If you want to color the text as well: */
.deposit-button input[type="radio"]:checked + .custom-control-label::after {
  color: #4CAF50;
}

.withdraw-button input[type="radio"]:checked + .custom-control-label::after {
  color: #F44336;
}
</style>
