<template>
  <div>
    <b-row>

      <b-col cols="4" xl="3" v-if="!newMonetary">
        <label>{{ $t('columns.id') }}</label>
        <b-form-input v-model="monetary.id" readonly/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.company') }}</label>
        <b-form-input v-model="monetary.company"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.group') }}</label>
        <b-form-input v-model="monetary.group"/>

      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.firstName') }}</label>
        <b-form-input v-model="monetary.firstName"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.lastName') }}</label>
        <b-form-input v-model="monetary.lastName"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.code') }}</label>
        <b-form-input v-model="monetary.code"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.alias') }}</label>
        <b-form-input v-model="monetary.alias"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.login') }}</label>
        <b-form-input v-model="monetary.login"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.password') }}</label>
        <b-form-input v-model="monetary.password"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.website') }}</label>
        <b-form-input v-model="monetary.website"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.skype') }}</label>
        <b-form-input v-model="monetary.skype"/>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.phone') }}</label>
        <b-input-group>
          <b-form-input v-model="monetary.phone"/>
          <b-input-group-append>
            <a
                class="btn btn-outline-success" target="_blank"
                v-b-tooltip :title="$t('operations.call')"
            >
              <i class="fa-solid fa-phone-volume text-primary"></i>
            </a>
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col cols="4" xl="3">
        <label>{{ $t('columns.email') }}</label>
        <b-input-group>
          <b-form-input v-model="monetary.email"/>
          <b-input-group-append>
            <a
                class="btn btn-outline-success" target="_blank"
                v-b-tooltip :title="$t('operations.sendMail')"
            >
              <i class="fa-solid fa-envelope"></i>
            </a>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.businessUnit') }}</label>
        <business-unit-select-box v-model="monetary.businessUnits"
                                  :disabled="!hasPermission(permissions.CLIENT_UPDATE_BU)"
        />
      </b-col>
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.orgId') }}</label>
        <organization-select-box v-model="monetary.orgId" :disabled="!hasPermission(permissions.CLIENT_UPDATE_ORG)"/>
      </b-col>

      <b-col cols="4" xl="3">
        <label class="invisible">{{ $t('columns.active') }}</label>
        <b-checkbox switch v-model="monetary.active">{{ $t('columns.active') }}</b-checkbox>
      </b-col>

      <!--Buy/Rent-->
      <b-col cols="4" xl="3">
        <label>{{ $t('columns.ftdVisible') }}</label>
        <v-select
            v-model="monetary.hideFtd"
            :options="[{value:true,label:$t('columns.hide')},{value:false,label:$t('columns.show')}]"
            class="w-100"
            label="label"
            :reduce="(op) => op.value"
        />
      </b-col>
    </b-row>

    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
      <b-button class="d-inline-block" size="sm" variant="outline-warning">{{ $t('operations.reset') }}</b-button>&nbsp;
      <b-button class="d-inline-block" size="sm" variant="outline-success" @click="save">{{ $t('operations.save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import Monetary from '@/model/monetary/Monetary';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import { mapActions, mapGetters } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox';
import CalendarPicker from '@/components/widget/picker/calendarPicker';
import mixinBase from '@/components/mixin/mixinBase';

export default {
  name: 'MonetaryEditDetails',
  components: {
    CalendarPicker,
    CountriesSelectBox,
    BusinessUnitSelectBox,
    OrganizationSelectBox
  },
  mixins: [mixinPermissions, mixinNotifications, mixinBase],
  data: () => ({
    monetary: Monetary(),

  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newMonetary: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.monetary = Object.assign(Monetary(), this.value);
      },
      immediate: true
    }
  },
  created() {
  },
  computed: {
    ...mapGetters('monetary', [''])
  },
  methods: {
    ...mapActions('monetary', ['updateMonetary', 'createMonetary']),

    async addMonetary() {
      let $this = this;
      this.createMonetary({
        monetary: this.monetary
      }).catch(err => {
            this.showErrorNotification(this.$t('globalMessages.fetchDataError', { system: this.$t('menu.monetaries') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.monetaries') }));
      this.$emit('input', this.monetary);
    /*   this.$emit('submitted', {
        status: true,
        server: id,
        id

      }); */

    },
    async modifyMonetary() {
      let $this = this;

      await this.updateMonetary({
        monetary: this.monetary,
        id: this.monetary.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.monetaries') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.monetaries') }));
      this.$emit('input', this.monetary);

    },

    async save() {
      if (this.monetary.id == null) {
        await this.addMonetary();
      } else {
        await this.modifyMonetary();
      }
    }
  }
};
</script>

<style scoped>
.table-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

.add-button {
  margin-left: 1rem;
}
</style>
