<template>
  <b-modal no-fade v-model="dialog.show" size="xl" :id="`modal-monetary-${uniqueId}`"
           :hide-footer="true" centered @close="closeModal"
           no-close-on-backdrop
  >
    <template #modal-title>
     <span v-if="loading !== true && error == null && monetary != null">
       {{ monetary.firstName }}  {{ monetary.lastName }}
     </span>
    </template>
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <b-tabs  v-model="dialog.tab" content-class="" align="center">
          <b-tab :title="$t('tabs.monetarys.details')" active>
<!--            <monetary-edit-details v-bind:new-monetary="isNewMonetary" @submitted="onSubmitted" v-model="monetary"/>
       -->   </b-tab>

        </b-tabs>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%"
           v-else-if="loading !== true && error != null"
      >
        <b-alert show variant="danger">
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{ $t('operations.loading') }}</b-spinner>
      </div>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';
import MonetaryEditDetails from '@/components/partial/monetaries/MonetaryEditDetails';
import Monetary from '@/store/system/monetary';

export default {
  name: 'monetaryModal',
  components: { MonetaryEditDetails },
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
      tab: 0,

    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewMonetary: false,
    monetary: {},
  }),

  watch: {
    queryParams: {
      handler() {
        if (this.queryParams.monetaryId == null) {
          this.closeModal();
          return;
        }
        if (this.queryParams.monetaryId !== this.id) {
          this.showModal(this.queryParams.monetaryId);
        }
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid;
    if (this.queryParams.monetaryId != null) {
      this.showModal(this.queryParams.monetaryId);
    }
  },
  methods: {
    ...mapActions('monetary', ['getMonetaryById']),
    showModal(id) {
      this.id = id;
      this.loading = true;
      this.dialog.show = true;
      this.dialog.tab = 0;
      this.isNewMonetary = this.id == null || this.id === 0;
      if (!this.isNewMonetary) {
        this.refreshData();
      } else {
        this.monetary = Monetary();
        this.error = null;
        this.loading = false;
      }
      this.pushState({
        monetaryId: id,
        tab: 0,
      });
    },
    closeModal() {
      this.dialog.show = false;
      this.monetary = {};
      this.onDialogClose();
    },
    onDialogClose() {
      this.id = null;
      this.pushState({
        monetaryId: null,
        tab: null,
      });
    },
    refreshData() {
      let $this = this;
      this.getMonetaryById(this.id)
          .then(monetary => {
            $this.monetary = monetary;
            $this.error = null;
            $this.loading = false;
          }, err => {
            $this.error = $this.getErrorDescription(err);
            $this.loading = false;
          });
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refreshData()
      this.dialog.tab = 0;
    }
  }
};
</script>

<style scoped>

</style>
