import { render, staticRenderFns } from "./monetaryModal.vue?vue&type=template&id=b13d4d70&scoped=true&"
import script from "./monetaryModal.vue?vue&type=script&lang=js&"
export * from "./monetaryModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b13d4d70",
  null
  
)

export default component.exports